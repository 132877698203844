// If you want to override variables do it here
@import "variables";
$enable-ltr: true;
$enable-rtl: true;
// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

.wrapper {
    width: 100%;
    @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
    will-change: auto;
    @include transition(padding .15s);
}